<template>
  <section class="space-1 bg-light sign-up-section">
    <div class="container">
      <div class="d-flex justify-content-center mb-5">
        <router-link to="/">
          <img class="logo d-flex"
               src="@/assets/img/brand/logo.svg" alt="Logo">
        </router-link>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 card card-body py-5 px-5">
          <div v-show="!passwordReset" class="px-3 py-3">
            <div class="mb-4">
              <h3>Reset Your Password</h3>
            </div>
            <form>
              <div class="form-group">
                <label for="password">New Password</label>
                <input type="password" v-model="password" class="form-control"
                       v-on:keyup.enter="resetPasswordFromTextbox()"
                       id="password" required>
                <small v-if="!isPasswordValid" class="form-text red">
                  The password needs to be at least 8 characters in length.</small>
              </div>
              <div class="form-group">
                <label for="repassword">Confirm password</label>
                <input type="password" v-model="rePassword" class="form-control"
                       v-on:keyup.enter="resetPasswordFromTextbox()"
                       id="repassword" required>
                <small v-if="!arePasswordsSame" class="form-text red">
                  The passwords need to match.</small>
              </div>
              <button type="button" @click="resetPassword()"
                      :disabled="isResetPasswordButtonDisabled"
                      class="btn btn-primary btn-block">
                <span v-if="processing">
                  <i class="fas fa-spin fa-spinner-third"></i>&nbsp;
                  Changing your password...
                </span>
                <span v-if="!processing">
                  Change Password
                </span>
              </button>
            </form>
            <div v-if="processing" class="progress-line blue-bar"></div>
            <div class="mt-5 text-center">
              <p class="m-0">Remember Password?</p>
              <router-link to="/login">Login</router-link>
            </div>
          </div>
          <div v-show="passwordReset" class="px-3 py-3">
            <div class="mb-4">
              <h3><i class="fad fa-glass-cheers"></i>&nbsp;
                Congratulations!</h3>
              <p class="text-muted">
                Your new password has been set! You can now login and start using trbo!</p>
              <router-link class="btn btn-primary wide" to="/login">
                <i class="fa fa-rocket-launch"></i>&nbsp;
                Login now</router-link>
            </div>
            <div class="mb-4">
              <img class="onboarding-image"
                   style="width: 100%"
                   src="@/assets/img/signup/awesome.webp" alt="You are awesome!" />
              <div class="image-attribution text-left">
                Colin Mochrie / Whose line is it anyway?</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import utils from '@/app/utils';
import userInfo from '@/app/user-info';

export default {
  components: { },
  computed: {
    isPasswordValid() {
      if (this.password == null || this.password.trim() === '') {
        return true;
      }

      return this.password.length >= 8;
    },
    arePasswordsSame() {
      // Check only after the first password is valid
      if (!this.isPasswordValid) {
        return true;
      }

      // Skip check if user has not entered the 2nd password.
      if (this.rePassword == null || this.rePassword.trim() === '') {
        return true;
      }

      return this.password === this.rePassword;
    },
    isResetPasswordButtonDisabled() {
      if (this.password == null || this.rePassword == null
          || this.password.trim() === '' || this.rePassword.trim() === '') {
        return true;
      }

      return !this.isPasswordValid
          || !this.arePasswordsSame || this.processing;
    },
  },
  data() {
    return {
      processing: false,
      password: '',
      rePassword: '',
      passwordReset: false,
    };
  },
  methods: {
    async resetPassword() {
      try {
        const { id, token } = this.$route.params;

        this.processing = true;
        await userInfo.resetUserPassword(id, token, this.password);

        this.processing = false;
        this.passwordReset = true;
      } catch (error) {
        this.processing = false;
        this.passwordReset = false;

        if (error.response && error.response.data && error.response.data.code) {
          const errorCode = error.response.data.code;

          if (errorCode === 'LINK_EXPIRED') {
            utils.showToastError('Link Expired',
              'This link has expired. Please request a new link from the password reset page.',
              true);
            return;
          }
        }

        utils.showToastError('Could not reset password',
          'An error occurred and your password could not be changed. '
            + 'Please click the link again from the email or request a new password reset link.',
          true);
      }
    },
    async resetPasswordFromTextbox() {
      if (!this.isResetPasswordButtonDisabled) {
        await this.resetPassword();
      }
    },
  },
};
</script>
